


























































































import { NftModel } from "@/models/nft-model";
import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Observer
@Component({})
export default class CharacterCard extends Vue {
  @Prop() character!: NftModel;
  @Prop({ default: 4 }) colSize!: number;
  @Prop({ default: false }) small!: boolean;
}
